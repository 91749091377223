import deephowApiServerWrapper from "@/server/api-server";

export const fetchSkills = ({ page = 1, size = 500 }) =>
  deephowApiServerWrapper("get", `/v1/skill/skills`, { page, size });

export const fetchSkillQuizStats = (id) => deephowApiServerWrapper("get", `/v1/skill/skills/${id}/quiz-stats`);

export const fetchSkillTeamUsers = ({ playlistId, teamId }) =>
  deephowApiServerWrapper("get", `/v1/skill/skills/${playlistId}/${teamId}/users`);

export const fetchSkillUsers = (playlistId) => deephowApiServerWrapper("get", `/v1/skill/skills/${playlistId}/users`);

export const getPlaylistById = async ({ playlistId }) =>
  await deephowApiServerWrapper("get", `/v1/playlists/${playlistId}`);

export const postPlaylists = async ({ organization, group, playlistTitle }) =>
  await deephowApiServerWrapper("post", "/v1/playlists", { organization, group, playlistTitle });

export const putPlaylistContent = async ({ playlistId, workflows }) =>
  await deephowApiServerWrapper("put", `/v1/playlists/${playlistId}/content`, { workflows });

export const deletePlaylistContent = async ({ playlistId, workflows }) =>
  await deephowApiServerWrapper("delete", `/v1/playlists/${playlistId}/content`, { data: { workflows } });

export const putPlaylist = async ({ playlistId, published, certifiers, playlistTitle, poster }) =>
  await deephowApiServerWrapper("put", `/v1/playlists/${playlistId}`, {
    published,
    certifiers,
    playlistTitle,
    ...(poster && { poster }),
  });
